import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, MenuItemText } from '@statsbomb/kitbag-components';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import ParsedLink from '../ParsedLink/ParsedLink';
import BadgeLabel from './BadgeLabel';
import { isPageSelected } from './SideNav.config';

const SubMenu = ({ menuItems, currentLocation, isMobile }) => {
  const { user, logout } = useKitbagAuth();
  const LogOut = () => {
    sessionStorage.removeItem('uiStateLocal');
    logout();
  };

  // showMenuItem checks if the user is in the correct userGroup to see the menu item
  const showMenuItem = (menuItem) =>
    (menuItem.userGroup &&
      menuItem.userGroup === user['https://statsbomb.com/accountID']) ||
    !menuItem.userGroup;

  return (
    <Menu variant="subnavigation">
      {menuItems.map((menuItem) =>
        showMenuItem(menuItem) ? (
          <ParsedLink href={menuItem.href} key={menuItem.id}>
            <MenuItem
              key={menuItem.id}
              size={isMobile ? 'touch' : 'small'}
              isSelected={isPageSelected(currentLocation, menuItem.id)}
              variant="subnavigation"
              opacity="secondary"
              onClick={() =>
                menuItem.name === 'Logout' ? LogOut() : undefined
              }
            >
              <MenuItemText isTouch={isMobile}>
                {menuItem.name}
                <BadgeLabel condition={menuItem?.wip} text="WIP" />
                <BadgeLabel condition={menuItem?.inBeta} text="BETA" />
              </MenuItemText>
            </MenuItem>
          </ParsedLink>
        ) : null
      )}
    </Menu>
  );
};

SubMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      userGroup: PropTypes.string,
      inBeta: PropTypes.bool,
    })
  ).isRequired,
  currentLocation: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default SubMenu;
